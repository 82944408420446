import { LitElement, html, css } from 'lit';
import { property, state } from 'lit/decorators.js';

export class TextStreamer extends LitElement {
  static styles = css`
    :host {
      display: block;
      padding: 16px;
      max-width: 800px;
      margin: 0 auto;
    }
    input {
      width: 100%;
      padding: 8px;
      margin-bottom: 16px;
    }
    button {
      padding: 8px 16px;
      background-color: #4CAF50;
      color: white;
      border: none;
      cursor: pointer;
    }
    .response {
      margin-top: 16px;
      white-space: pre-wrap;
    }
  `;

  @property({ type: String }) prompt = '';
  @state() response = '';

  private eventSource: EventSource | null = null;

  render() {
    return html`
      <input type="text" .value=${this.prompt} @input=${(e: InputEvent) => this.prompt = (e.target as HTMLInputElement).value} placeholder="Enter your prompt here" >
      <button @click=${this.streamResponse}>Get Response</button>
      <div class="response">${this.response}</div>
    `;
  }

  streamResponse() {
    this.response = '';
    if (this.eventSource) {
      this.eventSource.close();
    }

    this.eventSource = new EventSource(`/chatgpt/chat?text_input=${encodeURIComponent(this.prompt)}`);

    this.eventSource.onmessage = (event: MessageEvent) => {
      if (event.data === '[DONE]') {
        this.eventSource?.close();
      } else {
        const data = JSON.parse(event.data);
        if (data.text) {
          this.response += data.text;
          this.requestUpdate();
        } else if (data.error) {
          console.error('Error:', data.error);
          this.eventSource?.close();
        }
      }
    };

    this.eventSource.onerror = (error: Event) => {
      console.error('EventSource failed:', error);
      this.eventSource?.close();
    };
  }

  disconnectedCallback() {
    super.disconnectedCallback();
    this.eventSource?.close();
  }
}
