import { LitElement, html } from 'lit';

export class MyElement extends LitElement {
  version = '1.0';

  render() {
    return html`
    <p>Welcome to the Lit tutorial!</p>
    <p>This is the ${this.version} code.</p>
    `;
  }
}
