import { LitElement, html, css } from 'lit';
import { property } from 'lit/decorators.js';

export class StreamingMessages extends LitElement {
  @property({ type: Array }) messages: string[] = [];

  static styles = css`
    .messages {
      font-family: Arial, sans-serif;
    }
    .messages ul {
      list-style-type: none;
      padding: 0;
    }
    .messages li {
      background: #eee;
      margin: 5px 0;
      padding: 10px;
      border-radius: 4px;
    }
  `;

  connectedCallback() {
    super.connectedCallback();
    this.initEventSource();
  }

  disconnectedCallback() {
    this.eventSource?.close();
    super.disconnectedCallback();
  }

  eventSource: EventSource | undefined;

  initEventSource() {
    this.eventSource = new EventSource('/chatgpt/stream/');

    this.eventSource.onmessage = (event: MessageEvent) => {
      this.messages = [...this.messages, event.data];
    };

    this.eventSource.onerror = () => {
      this.eventSource?.close();
    };
  }

  render() {
    return html`
      <div class="messages">
        <h3>Streaming Messages</h3>
        <ul>
          ${this.messages.map(
            (message, index) => html`<li key="${index}">${message}</li>`
          )}
        </ul>
      </div>
    `;
  }
}
