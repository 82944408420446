// src/app.ts
import { LitElement, html, css } from 'lit';
import { property } from 'lit/decorators.js';

export class ImageDescriber extends LitElement {
  @property({ type: String }) private description: string = '';
  @property({ type: Boolean }) private loading: boolean = false;
  @property({ type: String }) private error: string | null = null;
  @property({ type: String }) imageUrl: string | undefined;

  static styles = css`
    :host {
      display: block;
      padding: 16px;
      max-width: 800px;
      margin: 0 auto;
    }
    input {
      width: 100%;
      padding: 8px;
      margin-bottom: 16px;
    }
    button {
      padding: 8px 16px;
      background-color: #4CAF50;
      color: white;
      border: none;
      cursor: pointer;
    }
    .response {
      margin-top: 16px;
      white-space: pre-wrap;
    }
  `;

  private async describeImage(prompt: string, imageUrl: string) {
    this.loading = true;
    this.error = null;
    this.description = '';
    try {
      const formData = new FormData();
      formData.append('prompt', prompt);
      formData.append('image_url', imageUrl);

      const response = await fetch('/chat/describe-image/', {
        method: 'POST',
        body: formData,
      });

      if (!response.ok) {
        const errorData = await response.json();
        this.error = errorData.error;
        this.loading = false;
        return;
      }

      const data = await response.json();
      this.description = data.description;
    } catch (err) {
      this.error = 'An error occurred while describing the image.';
    } finally {
      this.loading = false;
    }
  }

  private handleSubmit(e: Event) {
    e.preventDefault();
    const promptInput = this.shadowRoot!.querySelector<HTMLInputElement>('#prompt');
    const imageUrlInput = this.shadowRoot!.querySelector<HTMLInputElement>('#image_url');
    if (promptInput && promptInput.value && imageUrlInput && imageUrlInput.value) {
      this.describeImage(promptInput.value, imageUrlInput.value);
    }
    this.imageUrl = imageUrlInput?.value;
  }

  render() {
    return html`
        <h1>Talk with Image</h1>
        <form @submit="${this.handleSubmit}">
          <input id="prompt" type="text" placeholder="Enter your query about the image" required />
          <input id="image_url" type="text" placeholder="Enter the image URL" required />
          <button type="submit">Describe Image</button>
        </form>
        ${this.loading ? html`<p>Loading...</p>` : null}
        ${this.error ? html`<p>Error: ${this.error}</p>` : null}
        ${this.imageUrl ? html`<img class="image" width="400" src="${this.imageUrl}" alt="Generated Image" />` : null}
        <div class="output">${this.description}</div>
    `;
  }
}
