import {MyFileUploadComponent} from './file_upload.ts';
import {MyTotalCountComponent} from './total_count.ts';
import {LitCounterElement} from './vite_count.ts';
import {MyCounter} from './simple_count.ts';
import {StreamingMessages} from './streamming_messages.ts';
import {MyElement} from './litinit.ts';
import {NameTag} from './namer.ts';
import {ImageGenerator} from './generate_image.ts';
import {ImageDescriber} from './describe_image.ts';
import {TextStreamer} from './generate_text.ts';

window.customElements.define('my-file-upload', MyFileUploadComponent);
window.customElements.define('my-total-count', MyTotalCountComponent);
window.customElements.define('my-streamming-messages', StreamingMessages);
window.customElements.define('my-element', MyElement);
window.customElements.define('my-vite-counter', LitCounterElement);
window.customElements.define('my-namer', NameTag);
window.customElements.define('my-counter', MyCounter);
window.customElements.define('my-generate-image', ImageGenerator);
window.customElements.define('my-describe-image', ImageDescriber);
window.customElements.define('my-generate-text', TextStreamer);
