// src/app.ts
import { LitElement, html, css } from 'lit';
import { property } from 'lit/decorators.js';

export class ImageGenerator extends LitElement {
  @property({ type: String }) imageUrl: string | null = null;
  @property({ type: Boolean }) loading: boolean = false;
  @property({ type: String }) error: string | null = null;

  static styles = css`
    :host {
      display: block;
      padding: 16px;
      max-width: 800px;
      margin: 0 auto;
    }
    input {
      width: 100%;
      padding: 8px;
      margin-bottom: 16px;
    }
    button {
      padding: 8px 16px;
      background-color: #4CAF50;
      color: white;
      border: none;
      cursor: pointer;
    }
    .response {
      margin-top: 16px;
      white-space: pre-wrap;
    }
  `;

  private async generateImage(prompt: string) {
    this.loading = true;
    this.error = null;
    try {
      const response = await fetch('/chat/create-image/', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ text_input: prompt }),
      });

      const data = await response.json();
      if (response.ok) {
        this.imageUrl = data.image_url;
      } else {
        this.error = data.error;
      }
    } catch (err) {
      this.error = 'An error occurred while generating the image.';
    } finally {
      this.loading = false;
    }
  }

  private handleSubmit(e: Event) {
    e.preventDefault();
    const input = this.shadowRoot!.querySelector<HTMLInputElement>('#prompt-image');
    if (input && input.value) {
      this.generateImage(input.value);
    }
  }

  render() {
    return html`
        <h1>Generate Image</h1>
        <form @submit="${this.handleSubmit}">
          <input id="prompt-image" type="text" placeholder="Enter your prompt here" required />
          <button type="submit">Generate Image</button>
        </form>
        ${this.loading ? html`<p>Loading...</p>` : null}
        ${this.error ? html`<p>Error: ${this.error}</p>` : null}
        ${this.imageUrl ? html`<img class="image" src="${this.imageUrl}" alt="Generated Image" />` : null}
    `;
  }
}
