import {LitElement, html} from 'lit';
import {property} from 'lit/decorators.js';

export class MyCounter extends LitElement {
  @property({type: Number}) count = 0;
  protected render() {
    return html`
      <p><button @click="${this._increment}">Click Me!</button></p>
      <p>Click count: ${this.count}</p>
    `;
  }
  private _increment(e: Event) {
    const input = e.target as HTMLInputElement;
    console.log("getting event: " + input.value);
    this.count++;
  }
}
